// .storybook/manager.js
import { addons } from '@storybook/addons';
import theme from './ThemeSheetXL';

addons.setConfig({
  isFullscreen: false,
  showNav: true,
  showPanel: false,
  panelPosition: 'bottom',
  enableShortcuts: false,
  isToolshown: true,
  theme: theme,
  // selectedPanel: undefined,
  initialActive: 'sidebar',
  sidebar: {
    showRoots: false,
    collapsedRoots: ['other'],
  },
  toolbar: {
    title: { hidden: false, },
    zoom: { hidden: false, },
    eject: { hidden: false, },
    copy: { hidden: true, },
    fullscreen: { hidden: false, },
  },
});